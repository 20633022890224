var registerComponent = require('../core/component').registerComponent;
var THREE = require('../lib/three');
var utils = require('../utils/');

var warn = function(msg) {
    utils.debug('components:gltf-model:warn')(msg);
};
var LOADING_MODELS = {};
var MODELS = {};

/**
 * glTF model loader.
 */
module.exports.Component = registerComponent('gltf-model', {
  schema: {
      src: { type: 'model' },
      part: { type: 'string' },
      partType: { type: 'string' },
      extractPosition: { type: 'boolean' },
      extractRotation: { type: 'boolean' },
      exposeNodes: { type: "array" },
      extractAnimation: { type: 'boolean' }
  },

  init: function () {
    this.model = null;
    this.isMesh = true;
    this.exposedNodes = {};
    this.nodeMapping = {};
    var dracoLoader = this.system.getDRACOLoader();
    var ktx2Loader = this.system.getKTX2Loader();
    var loader = new THREE.GLTFLoader();
    if (dracoLoader) {
      loader.setDRACOLoader(dracoLoader);
    }
    if(ktx2Loader) {
        loader.setKTX2Loader(ktx2Loader);
    }
    this.loader=loader;
    this.extractModel = this.extractModel.bind(this);
  },

  getLoader: function () {
    return this.loader;
  },
  //
  // updateSchema: function (data) {
  //   const newNodes = data && data.exposeNodes;
  //   const currentNodes = this.oldData && this.oldData.exposeNodes;
  //   if (currentNodes && newNodes === currentNodes) { return; }
  //   this.extendSchema(data);
  // },

    extractModel( model){

    const { el, data, } = this;
    const {   extractPosition, extractRotation,  extractAnimation, exposeNodes  } = data;

      let { modelPart, animations, partName, src} = model;
      if (!modelPart) { return; }
        const schema = {};

      const envmapPromise = this.system.getEnvMapTexture();
      if (envmapPromise){
          envmapPromise.then((envmap) => {
              modelPart.traverse(node => {

                  if (node.material) {
                      node.material.envMap = envmap;
                      node.material.envMap.intensity = 30;
                      node.material.needsUpdate = true;
                  }
              });
          });
      }
      if (exposeNodes && exposeNodes.length) {
          exposeNodes.forEach(mapping => {
              const nodeName = mapping.split('=')[0];
              const nodeId = mapping.split('=')[1];
              this.nodeMapping[nodeName] = {id: nodeId};
              schema[nodeId] = {type: "object"};
          });
          modelPart.traverse(node => {

            if( node.name in this.nodeMapping) {
                this.exposedNodes[this.nodeMapping[node.name].id]= node;
            }
          });
          Object.entries(this.nodeMapping).forEach(k => {
              if (!this.exposedNodes[k[1].id]) {
                  var message = `Failed to map node ${k[1].id} for part ${partName}`;
                  warn(message);
                  el.emit('model-error', {format: 'gltf', src: src});
              }
          })
      }

      if (extractPosition) {
        const pos = new THREE.Vector3();
        pos.copy(modelPart.position);
        modelPart.position.set(0, 0, 0);
        el.setAttribute('position', {x: pos.x, y: pos.y, z: pos.z});
      }

      if (extractRotation) {
        const rot = new THREE.Vector3();
        rot.copy(modelPart.rotation);
        modelPart.rotation.set(0, 0, 0);
        el.setAttribute('rotation', {x: rot.x, y: rot.y, z: rot.z});
      }

      if (extractAnimation) {
          el.object3D.name = modelPart.name;
          modelPart.name = `${el.object3D.name}_null`;
          el.object3D.animations = animations;
      } else {
          modelPart.animations = animations;
      }

          el.setObject3D('mesh', modelPart);
          el.emit('model-loaded', {format: 'gltf', model: modelPart});

    },

  update: function () {


    this.getModel(this.extractModel);
  },

    getModel: function(cb) {
     var self = this;
     var { src, part }  = this.data;
     var el = this.el;

    // Already parsed, grab it.
    if (MODELS[src]) {
      cb(this.selectFromModel(MODELS[src]));
      return;
    }

    // Currently loading, wait for it.
    if (LOADING_MODELS[src]) {
      return LOADING_MODELS[src].then(function (model) {
        cb(self.selectFromModel(model));
      });
    }

    // Not yet fetching, fetch it.
    LOADING_MODELS[src] = new Promise(function (resolve) {
        var loader = self.getLoader();
        loader.load(src, function (gltfModel) {
        let pathCache = {};
        const optimise = self.system.getEnableOptimize();
        gltfModel.animations.forEach(clip => {
          if (optimise) clip.optimize();
          pathCache[clip.name] = {};
          clip.tracks.forEach(track => {
              pathCache[clip.name][track.name] = THREE.PropertyBinding.parseTrackName(track.name);
          });
         });
        MODELS[src] = {model: gltfModel, parsedClips: pathCache, partname: src};
        delete LOADING_MODELS[src];
        cb(self.selectFromModel(MODELS[src]));
        resolve(MODELS[src]);
      },  undefined /* onProgress */, function gltfFailed (error) {
          var message = (error && error.message) ? error.message : 'Failed to load glTF model';
          warn(message);
          el.emit('model-error', {format: 'gltf', src: src});
        });
    });
  },

  selectFromModel: function (cached) {
     var {
          model,
         parsedClips
      } = cached;

    var el = this.el;
    var { src, part, partType,   extractTransform,  extractAnimation  } = this.data;
    var matched = [];
    let scene = model.scene || model.scenes[0];
    if (part && part.length > 0) {
      const threeType = THREE[partType] || null;
      const nameRe = new RegExp(part) || null;

      scene.traverse(function (element) {
        if ((nameRe ? nameRe.test(element.name) : true) &&
            (threeType ? element instanceof threeType : true)) {
          matched.push(element);
        }
      });
      let _export;

      if (matched.length === 1){
          _export = matched[0]
      } else {
           const root = new THREE.Group();

          matched.forEach(node=> {
            root.attach(node);
          });
          _export = root;
      }


      if (_export === undefined) {
          var message = 'Failed to find glTF subscene: '+part+' in: '+src;
          warn(message);
          el.emit('model-error', {format: 'gltf', src: src});
          return undefined;
      }
      const exportNames = [];
      _export.traverse(child => {
          exportNames.push(child.name);
      });

      const animations =model.animations.map(clip => {
   		const parsedPaths = parsedClips[clip.name];
        const tracks = [];
        for ( let i = 0; i < clip.tracks.length; i ++ ) {
            const path = parsedPaths[clip.tracks[ i ].name];
            if (exportNames.includes(path.nodeName)) {
                tracks.push(clip.tracks[ i ].clone());
            }
        }
		return new THREE.AnimationClip( clip.name, clip.duration, tracks, clip.blendMode );
      });

      return { modelPart: _export, animations: animations, partName: part, src: src  };
    }
    else {
        return { modelPart: scene, animations: model.animations, partName: part, src: src };
    }
  },
  remove: function () {
    if (!this.model) { return; }
    this.el.removeObject3D('mesh');
  }
});
