var registerSystem = require('../core/system').registerSystem;
var utils = require('../utils');
var THREE = require('../lib/three');

/**
 * glTF model system.
 *
 * Configures glTF loading options. Models using glTF compression require that a Draco decoder be
 * provided externally.
 *
 * @param {string} dracoDecoderPath - Base path from which to load Draco decoder library.
 */
module.exports.System = registerSystem('gltf-model', {
  schema: {
    dracoDecoderPath: {type: 'string', default: ''},
    basisTranscoderPath: {type: 'string', default: ''},
    optimize: {type: 'boolean', default: false},
    envMap: {type: 'asset'}
  },

  init: function () {
    this.dracoLoader = null;
    this.ktx2Loader = null;
    this.enableOptimize = false;
    this.envMapTexture = null;
    this.pmremGen = null;
    this.envMapLoading = null;
  },
  requestEnvMap() {
    if (this.envMapTexture) {
        return new Promise( (resolve) => resolve(this.envMapTexture));
    }
    if (!this.pmremGen) {
        var gl = this.el.sceneEl.renderer;
        this.pmremGen = new THREE.PMREMGenerator(gl);
    }
    const scope = this;
    const {basisTranscoderPath, dracoDecoderPath, envMap , optimize } = this.data;
    this.envMapLoading = new Promise((resolve)=> {
       utils.srcLoader.validateSrc(envMap,  (src) => resolve(src), null);
     }).then(src =>  new Promise ((resolve) =>
         new THREE.RGBELoader().setDataType(THREE.UnsignedByteType).load(src, (hdrEquiRect, textureData) => resolve(hdrEquiRect))
    )).then(equiRectTexture => {
        const hdrCubeRenderTarget = scope.pmremGen.fromEquirectangular(equiRectTexture);
        scope.pmremGen.compileCubemapShader();

        return hdrCubeRenderTarget.texture;
    });

    return this.envMapLoading;
  },
  update: function () {
    const {basisTranscoderPath, dracoDecoderPath, envMapPosX, optimize } = this.data;
    if (!this.dracoLoader) {
      this.dracoLoader = new THREE.DRACOLoader();
      this.dracoLoader.setDecoderPath(dracoDecoderPath);
    }
    if (!this.ktx2Loader && !(!basisTranscoderPath || 0 === basisTranscoderPath.length)) {

     var gl = this.el.sceneEl.renderer;
     this.ktx2Loader = new THREE.KTX2Loader();
     this.ktx2Loader.setTranscoderPath(basisTranscoderPath);
     this.ktx2Loader.detectSupport(gl);
    }
    this.enableOptimize = optimize;
  },

  getDRACOLoader: function () {
    return this.dracoLoader;
  },

  getKTX2Loader: function () {
    return this.ktx2Loader;
  },


  getEnvMapTexture: function () {
          const {envMap } = this.data;
          if (envMap) {
              if (this.envMapLoading)
                  return this.envMapLoading;
              else
                  return this.requestEnvMap();
          }
          else return null;
  },

  getEnableOptimize: function () {
    return this.enableOptimize;
  }

});
