var registerPrimitive = require('../primitives').registerPrimitive;

registerPrimitive('a-gltf-model', {
  mappings: {
    src: 'gltf-model.src',
    part: 'gltf-model.part',
    'part-type': 'gltf-model.partType',
    'extract-animation': 'gltf-model.extractAnimation',
    'extract-position': 'gltf-model.extractPosition',
    'extract-rotation': 'gltf-model.extractRotation',
    'expose-nodes': 'gltf-model.exposeNodes'
  }
});
