/* global DeviceOrientationEvent, location  */
var registerComponent = require('../../core/component').registerComponent;
var utils = require('../../utils/');
var bind = utils.bind;

var constants = require('../../constants/');

var MODAL_CLASS = 'a-modal';
var DIALOG_CLASS = 'a-dialog';
var DIALOG_TEXT_CLASS = 'a-dialog-text';
var DIALOG_TEXT_CONTAINER_CLASS = 'a-dialog-text-container';
var DIALOG_BUTTONS_CONTAINER_CLASS = 'a-dialog-buttons-container';
var DIALOG_BUTTON_CLASS = 'a-dialog-button';
var DIALOG_ALLOW_BUTTON_CLASS = 'a-dialog-allow-button';
var DIALOG_DENY_BUTTON_CLASS = 'a-dialog-deny-button';
var DIALOG_OK_BUTTON_CLASS = 'a-dialog-ok-button';

/**
 * UI for enabling device motion permission
 */
module.exports.Component = registerComponent('device-orientation-permission-ui', {
  schema: {
    enabled: {default: true},
    deviceMotionMessage: {
      default: 'This immersive website requires access to your device motion sensors.'
    },
    mobileDesktopMessage: {
      default: 'Set your browser to request the mobile version of the site and reload the page to enjoy immersive mode.'
    },
    httpsMessage: {
      default: 'Access this site over HTTPS to enter VR mode and grant access to the device sensors.'
    },
    denyButtonText: {default: 'Deny'},
    allowButtonText: {default: 'Allow'},
    cancelButtonText: {default: 'Cancel'},
    permissionGranted: {default: false}
  },

  init: function () {
    var self = this;

    if (!this.data.enabled) { return; }

    if (location.hostname !== 'localhost' &&
        location.hostname !== '127.0.0.1' &&
        location.protocol === 'http:') {
      console.log(self.data.httpsMessage.default);
    }

    // Show alert on iPad if Safari is on desktop mode.
    if (utils.device.isMobileDeviceRequestingDesktopSite()) {
      console.log(self.data.mobileDesktopMessage.default);
      return;
    }

    // Browser doesn't support or doesn't require permission to DeviceOrientationEvent API.
    if (typeof DeviceOrientationEvent === 'undefined') {
      console.log(self.data.deviceMotionMessage.default);
      self.el.setAttribute('device-orientation-permission-ui', {permissionGranted: true});
      self.el.emit('deviceorientationpermissiongranted');
      return;
    }

    // Show dialog only if permission has not yet been granted.

    self.el.emit('deviceorientationpermissionrequested');
    if (typeof(DeviceOrientationEvent.requestPermission) === "function") {
      DeviceOrientationEvent.requestPermission().then(function (response) {
        if (response === 'granted') {
          self.el.setAttribute('device-orientation-permission-ui', {permissionGranted: true});
          self.el.emit('deviceorientationpermissiongranted');
        } else {
          self.el.emit('deviceorientationpermissionrejected');
        }
        self.remove();
      }).catch(console.error);
    } else {
      self.el.setAttribute('device-orientation-permission-ui', {permissionGranted: true});
      self.el.emit('deviceorientationpermissiongranted');
    }
  }

});
